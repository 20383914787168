<template>
	<div :id="$vnode.key" class="c-block-list-news-list-custom">
		<div class="flex justify-between items-center mb-sm >=768:mb-xl">
			<div
				:class="[
					'flex items-center justify-between >=768:justify-start',
					'space-x-lg w-full >=768:w-auto',
				]"
			>
				<BaseH2 v-if="title" class="mb-8" v-text="title" />

				<div class="space-x-sm">
					<!-- Previous -->
					<BaseButton
						light
						:aria-disabled="!showPrevious"
						class="w-40 h-40 transform rotate-180"
						aria-label="tidligere element"
						@click="gotoPrevious"
					>
						<template #icon>
							<SvgCaret
								class="
									transform
									-rotate-90
									translate-x-1 translate-y-1
								"
							/>
						</template>
					</BaseButton>

					<!-- Next -->
					<BaseButton
						light
						:aria-disabled="!showNext"
						class="w-40 h-40"
						aria-label="næste element"
						@click="gotoNext"
					>
						<template #icon>
							<SvgCaret
								class="
									transform
									-rotate-90
									translate-x-1 translate-y-1
								"
							/>
						</template>
					</BaseButton>
				</div>
			</div>

			<!-- See all -->
			<NuxtLinkExt
				v-if="archive && archive[0]"
				:to="archive[0].url"
				:target="archive[0].target"
				class="hidden >=768:block"
			>
				<BaseButton tag="div" light>
					<template #default>
						<span v-text="'Se alle'"></span>
					</template>

					<template #icon>
						<SvgArrow />
					</template>
				</BaseButton>
			</NuxtLinkExt>
		</div>

		<!-- Swiper -->
		<SwiperWrap
			ref="swiper"
			:move-by="1"
			class="c-block-list-news-list-custom__swiper relative"
			:items-wrapper-class-names="[
				'flex w-full',
				'snap snap-x snap-mandatory',
				'space-x-layout-gutter overflow-x-auto',
				'py-sm',
			]"
			@state-change="onStateChange"
		>
			<!-- Items -->
			<template #items>
				<ContentCard
					v-for="(item, index) in items"
					:key="`news-${index}`"
					:title="item.title || item.name"
					:teaser="item.teaser"
					:link="{ url: item.url, target: item.target }"
					:class="[
						'c-block-list-news-list-custom__item',
						'flex-shrink-0 >=768:w-6/12col >=1024:w-4/12col',
						'w-full snap-start',
						'duration-500 ease-smooth-out',
					]"
					:image="item.image"
					:use-fallback-image="1"
					:data-index="index"
					light
				>
					<template #before>
						<div
							class="font-darker-grotesque font-semibold mb-6"
							v-text="formatDate(item.newsDate)"
						></div>
					</template>
				</ContentCard>
			</template>

			<!-- Gradients -->
			<template #default>
				<div
					class="c-block-list-news-list-custom__gradient-first"
				></div>
				<div
					class="c-block-list-news-list-custom__gradient-second"
				></div>
			</template>
		</SwiperWrap>

		<!-- See all mobile -->
		<NuxtLinkExt
			v-if="archive && archive[0]"
			:to="archive[0].url"
			:target="archive[0].target"
			class=">=768:hidden"
		>
			<BaseButton tag="div" class="max-w-fit mt-md" light>
				<template #default>
					<span v-text="'Se alle'"></span>
				</template>

				<template #icon>
					<SvgArrow />
				</template>
			</BaseButton>
		</NuxtLinkExt>
	</div>
</template>

<script>
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgCaret from '~/assets/svgs/icon-caret.svg?inline';
import SwiperWrap from '~/citi-baseline/components/SwiperWrap';
import ContentCard from '~/components/shared/ContentCard';

export default {
	name: 'BlockListNewsListCustom',
	components: { SvgArrow, SvgCaret, SwiperWrap, ContentCard },
	inheritAttrs: false,

	props: {
		title: String,
		archive: Array,
		items: Array,
	},

	data() {
		return {
			showPrevious: false,
			showNext: false,
		};
	},

	methods: {
		gotoPrevious() {
			if (this.$refs?.swiper) {
				this.$refs.swiper.previousItems();
			}
		},

		gotoNext() {
			if (this.$refs?.swiper) {
				this.$refs.swiper.nextItems();
			}
		},

		onStateChange(value) {
			this.showPrevious = value.showPrevious;
			this.showNext = value.showNext;
		},

		formatDate(dateString) {
			const date = new Date(dateString);

			const day = `0${date.getDate()}`.slice(-2);
			const month = `0${date.getMonth() + 1}`.slice(-2);
			const year = date.getFullYear();

			return `${day}.${month}.${year}`;
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-news-list-custom__swiper {
	@apply -mx-layout-margin;

	& .c-swiper-wrap__content {
		@apply px-layout-margin snap-px-layout-margin;
	}
}

@screen >=1440 {
	.c-block-list-news-list-custom__swiper {
		margin-left: max(
			-186px,
			0px - (var(--visual-viewport-width, 100vw) - 1440px) / 2 -
				var(--theme-layout-margin)
		);
		margin-right: max(
			-186px,
			0px - (var(--visual-viewport-width, 100vw) - 1440px) / 2 -
				var(--theme-layout-margin)
		);

		& .c-swiper-wrap__content {
			padding: min(
				186px,
				(var(--visual-viewport-width, 100vw) - 1440px) / 2 +
					var(--theme-layout-margin)
			);
			scroll-padding-left: min(
				186px,
				(var(--visual-viewport-width, 100vw) - 1440px) / 2 +
					var(--theme-layout-margin)
			);

			@apply py-0 snap-py-0;
		}
	}

	.c-block-list-news-list-custom__gradient-first,
	.c-block-list-news-list-custom__gradient-second {
		position: absolute;
		top: 0;
		width: 155px;
		height: 100%;
	}

	.c-block-list-news-list-custom__gradient-first {
		left: min(
			(
					(
							(var(--visual-viewport-width, 100vw) - 1440px) / 2 +
								var(--theme-layout-margin)
						) - 155px
				) / 2 - 1px,
			-1px
		);
		background: rgb(255, 255, 255);
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	.c-block-list-news-list-custom__gradient-second {
		right: min(
			(
					(
							(var(--visual-viewport-width, 100vw) - 1440px) / 2 +
								var(--theme-layout-margin)
						) - 155px
				) / 2 - 1px,
			-1px
		);
		background: rgb(255, 255, 255);
		background: linear-gradient(
			270deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
}

@screen <768 {
	.c-block-list-news-list-custom__item {
		width: calc(
			var(--theme-layout-column-of-5) * 5 + var(--theme-layout-gutter) * 4 -
				40px
		) !important;
	}
}
</style>

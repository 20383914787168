<template>
	<LinkTile
		v-if="link"
		class="c-content-card group"
		:to="hasExternalOutboundRedirect ? externalOutboundRedirect : link.url"
		:target="hasExternalOutboundRedirect ? '_blank' : link.target"
		v-bind="$attrs"
		:aria-labelledby="ariaLabelledby"
		:aria-describedby="ariaDescribedby"
		v-on="$listeners"
	>
		<div
			:class="[
				'transform h-full',
				'duration-500 ease-smooth-out',
				'group-hover:-translate-x-5 group-hover:-translate-y-5',
				'group-hover:bg-primary group-hover:bg-opacity-50',
			]"
		>
			<div
				:class="[
					'relative',
					'bg-background flex flex-col h-full',
					'transform duration-500 ease-smooth-out',
					'group-hover:bg-secondary group-hover:bg-opacity-80',
					'group-hover:translate-x-10 group-hover:translate-y-10',
				]"
			>
				<UmbracoImageExt
					v-if="image"
					:widths="[345, 459]"
					:alt="image.altText"
					:source-url="image.cropUrl"
					:source-width="image.width"
					:source-height="image.height"
					:aspect-ratio="416 / 243"
				/>
				<FallbackImage
					v-else-if="
						typeof useFallbackImage === 'number' || useFallbackImage
					"
					:aspect-ratio="416 / 243"
					:variant="
						typeof useFallbackImage === 'number'
							? useFallbackImage
							: undefined
					"
				/>

				<div class="flex-1 flex flex-col justify-between px-xl py-lg">
					<slot v-bind="{ titleId, descriptionId }">
						<div>
							<div
								v-if="$scopedSlots.before"
								class="text-label-card"
							>
								<slot name="before"></slot>
							</div>

							<div class="space-y-md">
								<HeadingX
									v-if="title"
									:id="titleId"
									:class="[
										'font-darker-grotesque font-bold',
										'text-title-card ',
									]"
									:level-offset="2"
									v-html="title"
								></HeadingX>
								<div
									v-if="teaser"
									:id="descriptionId"
									class="text-teaser-sm font-normal"
									v-text="teaser"
								></div>

								<slot name="content"></slot>
							</div>
						</div>

						<slot name="after">
							<div class="flex items-center space-x-12 mt-lg">
								<div
									:class="[
										'flex items-center justify-center',
										'w-24 h-24 rounded-full bg-black text-white',
									]"
								>
									<SvgArrow class="w-12" />
								</div>

								<span
									class="
										font-bold font-darker-grotesque
										uppercase
										text-button-xs
									"
									v-text="'Læs mere'"
								></span>
							</div>
						</slot>
					</slot>
				</div>

				<!-- Bottom placed button -->
				<div
					v-if="$scopedSlots.button"
					@click="(e) => e.stopPropagation()"
				>
					<slot name="button"></slot>
				</div>
			</div>
		</div>
	</LinkTile>
</template>

<script>
import HeadingX from '~/citi-baseline/components/HeadingX';
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import LinkTile from '~/citi-baseline/components/LinkTile';
import UmbracoImageExt from '~/components/shared/UmbracoImageExt';
import FallbackImage from '~/components/shared/FallbackImage';

export default {
	name: 'ContentCard',

	components: {
		HeadingX,
		SvgArrow,
		LinkTile,
		UmbracoImageExt,
		FallbackImage,
	},
	inheritAttrs: false,

	props: {
		image: Object,
		useFallbackImage: [Boolean, Number],
		title: String,
		teaser: String,

		link: {
			type: Object,
			required: true,
		},

		hasExternalOutboundRedirect: {
			type: Boolean,
			default: false,
		},

		externalOutboundRedirect: {
			type: String,
			default: null,
		},
	},

	computed: {
		// For aria considerations
		titleId() {
			return `content-card-${this._uid}-title`;
		},
		descriptionId() {
			return `content-card-${this._uid}-description`;
		},
		ariaLabelledby() {
			if (
				!this.title ||
				this.$scopedSlots.default ||
				this.$attrs.ariaLabel ||
				this.$attrs.ariaLabelledby
			) {
				return this.$attrs.ariaLabelledby;
			}
			return this.titleId;
		},
		ariaDescribedby() {
			if (
				!this.teaser ||
				this.$scopedSlots.default ||
				this.$attrs.ariaDescribedby
			) {
				return this.$attrs.ariaDescribedby;
			}
			return this.descriptionId;
		},
	},
};
</script>

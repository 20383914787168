var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-block-list-news-list-custom",attrs:{"id":_vm.$vnode.key}},[_c('div',{staticClass:"flex justify-between items-center mb-sm >=768:mb-xl"},[_c('div',{class:[
				'flex items-center justify-between >=768:justify-start',
				'space-x-lg w-full >=768:w-auto',
			]},[(_vm.title)?_c('BaseH2',{staticClass:"mb-8",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"space-x-sm"},[_c('BaseButton',{staticClass:"w-40 h-40 transform rotate-180",attrs:{"light":"","aria-disabled":!_vm.showPrevious,"aria-label":"tidligere element"},on:{"click":_vm.gotoPrevious},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{staticClass:"transform -rotate-90 translate-x-1 translate-y-1"})]},proxy:true}])}),_vm._v(" "),_c('BaseButton',{staticClass:"w-40 h-40",attrs:{"light":"","aria-disabled":!_vm.showNext,"aria-label":"næste element"},on:{"click":_vm.gotoNext},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCaret',{staticClass:"transform -rotate-90 translate-x-1 translate-y-1"})]},proxy:true}])})],1)],1),_vm._v(" "),(_vm.archive && _vm.archive[0])?_c('NuxtLinkExt',{staticClass:"hidden >=768:block",attrs:{"to":_vm.archive[0].url,"target":_vm.archive[0].target}},[_c('BaseButton',{attrs:{"tag":"div","light":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s('Se alle')}})]},proxy:true},{key:"icon",fn:function(){return [_c('SvgArrow')]},proxy:true}],null,false,3728198737)})],1):_vm._e()],1),_vm._v(" "),_c('SwiperWrap',{ref:"swiper",staticClass:"c-block-list-news-list-custom__swiper relative",attrs:{"move-by":1,"items-wrapper-class-names":[
			'flex w-full',
			'snap snap-x snap-mandatory',
			'space-x-layout-gutter overflow-x-auto',
			'py-sm',
		]},on:{"state-change":_vm.onStateChange},scopedSlots:_vm._u([{key:"items",fn:function(){return _vm._l((_vm.items),function(item,index){return _c('ContentCard',{key:`news-${index}`,class:[
					'c-block-list-news-list-custom__item',
					'flex-shrink-0 >=768:w-6/12col >=1024:w-4/12col',
					'w-full snap-start',
					'duration-500 ease-smooth-out',
				],attrs:{"title":item.title || item.name,"teaser":item.teaser,"link":{ url: item.url, target: item.target },"image":item.image,"use-fallback-image":1,"data-index":index,"light":""},scopedSlots:_vm._u([{key:"before",fn:function(){return [_c('div',{staticClass:"font-darker-grotesque font-semibold mb-6",domProps:{"textContent":_vm._s(_vm.formatDate(item.newsDate))}})]},proxy:true}],null,true)})})},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"c-block-list-news-list-custom__gradient-first"}),_vm._v(" "),_c('div',{staticClass:"c-block-list-news-list-custom__gradient-second"})]},proxy:true}])}),_vm._v(" "),(_vm.archive && _vm.archive[0])?_c('NuxtLinkExt',{staticClass:">=768:hidden",attrs:{"to":_vm.archive[0].url,"target":_vm.archive[0].target}},[_c('BaseButton',{staticClass:"max-w-fit mt-md",attrs:{"tag":"div","light":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s('Se alle')}})]},proxy:true},{key:"icon",fn:function(){return [_c('SvgArrow')]},proxy:true}],null,false,3728198737)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
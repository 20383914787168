var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.link)?_c('LinkTile',_vm._g(_vm._b({staticClass:"c-content-card group",attrs:{"to":_vm.hasExternalOutboundRedirect ? _vm.externalOutboundRedirect : _vm.link.url,"target":_vm.hasExternalOutboundRedirect ? '_blank' : _vm.link.target,"aria-labelledby":_vm.ariaLabelledby,"aria-describedby":_vm.ariaDescribedby}},'LinkTile',_vm.$attrs,false),_vm.$listeners),[_c('div',{class:[
			'transform h-full',
			'duration-500 ease-smooth-out',
			'group-hover:-translate-x-5 group-hover:-translate-y-5',
			'group-hover:bg-primary group-hover:bg-opacity-50',
		]},[_c('div',{class:[
				'relative',
				'bg-background flex flex-col h-full',
				'transform duration-500 ease-smooth-out',
				'group-hover:bg-secondary group-hover:bg-opacity-80',
				'group-hover:translate-x-10 group-hover:translate-y-10',
			]},[(_vm.image)?_c('UmbracoImageExt',{attrs:{"widths":[345, 459],"alt":_vm.image.altText,"source-url":_vm.image.cropUrl,"source-width":_vm.image.width,"source-height":_vm.image.height,"aspect-ratio":416 / 243}}):(
					typeof _vm.useFallbackImage === 'number' || _vm.useFallbackImage
				)?_c('FallbackImage',{attrs:{"aspect-ratio":416 / 243,"variant":typeof _vm.useFallbackImage === 'number'
						? _vm.useFallbackImage
						: undefined}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-1 flex flex-col justify-between px-xl py-lg"},[_vm._t("default",function(){return [_c('div',[(_vm.$scopedSlots.before)?_c('div',{staticClass:"text-label-card"},[_vm._t("before")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"space-y-md"},[(_vm.title)?_c('HeadingX',{class:[
									'font-darker-grotesque font-bold',
									'text-title-card ',
								],attrs:{"id":_vm.titleId,"level-offset":2},domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.teaser)?_c('div',{staticClass:"text-teaser-sm font-normal",attrs:{"id":_vm.descriptionId},domProps:{"textContent":_vm._s(_vm.teaser)}}):_vm._e(),_vm._v(" "),_vm._t("content")],2)]),_vm._v(" "),_vm._t("after",function(){return [_c('div',{staticClass:"flex items-center space-x-12 mt-lg"},[_c('div',{class:[
									'flex items-center justify-center',
									'w-24 h-24 rounded-full bg-black text-white',
								]},[_c('SvgArrow',{staticClass:"w-12"})],1),_vm._v(" "),_c('span',{staticClass:"font-bold font-darker-grotesque uppercase text-button-xs",domProps:{"textContent":_vm._s('Læs mere')}})])]})]},null,{ titleId: _vm.titleId, descriptionId: _vm.descriptionId })],2),_vm._v(" "),(_vm.$scopedSlots.button)?_c('div',{on:{"click":(e) => e.stopPropagation()}},[_vm._t("button")],2):_vm._e()],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }